import { Database } from "@nozbe/watermelondb"
import LokiJSAdapter from "@nozbe/watermelondb/adapters/lokijs"

import { mySchema } from "./schema"
import Attachment from "./Attachment"
import WorkType from "./WorkType"
import LineStyle from "./LineStyle"
import MapSymbol from "./MapSymbol"
import Upload from "./Upload"

const adapter = new LokiJSAdapter({
  dbName: "MapSource",
  schema: mySchema,
  useWebWorker: false,
  useIncrementalIndexedDB: true,

  onQuotaExceededError: (error) => {
    // Browser ran out of disk space -- offer the user to reload the app or log out
    console.log("QuotaExceededError", error)
  },
  onSetUpError: (error) => {
    // Database failed to load -- offer the user to reload the app or log out
    console.log("onSetUpError", error)
  },
  extraIncrementalIDBOptions: {
    onDidOverwrite: () => {
      // Called when this adapter is forced to overwrite contents of IndexedDB.
      // This happens if there's another open tab of the same app that's making changes.
      // Try to synchronize the app now, and if user is offline, alert them that if they close this
      // tab, some data may be lost
    },
    onversionchange: () => {
      // database was deleted in another browser tab (user logged out), so we must make sure we delete
      // it in this tab as well - usually best to just refresh the page
      // if (checkIfUserIsLoggedIn()) {
      //   window.location.reload()
      // }
    },
  },
})

export const database = new Database({
  adapter,
  modelClasses: [Attachment, WorkType, LineStyle, MapSymbol, Upload],
})
