import { appSchema, tableSchema } from "@nozbe/watermelondb"

export enum TableName {
  Attachment = "attachments",
  // Survey = "surveys",
  // Feature = "features",
  // Form = "forms",
  WorkType = "workTypes",
  // FeatureType = "featureTypes",
  // Project = "projects",
  // World = "worlds",
  // User = "users",
  // Point = "points",
  LineStyle = "lineStyles",
  MapSymbol = "mapSymbols",
  // Device = "devices",
  // Category = "categories",
  // AuditLog = "auditLogs",
  Upload = "uploads",
}

export const mySchema = appSchema({
  version: 2,
  tables: [
    tableSchema({
      name: TableName.Attachment,
      columns: [
        { name: "attachmentId", type: "number" },
        { name: "formId", type: "number" },
        { name: "path", type: "string" },
        { name: "name", type: "string" },
        { name: "fileName", type: "string" },
        { name: "data", type: "string" },
        { name: "url", type: "string" },
        { name: "fieldId", type: "string" },
        { name: "latitude", type: "number", isOptional: true },
        { name: "longitude", type: "number", isOptional: true },
        { name: "orientation", type: "number", isOptional: true },
        { name: "type", type: "string", isOptional: true },
        { name: "objectId", type: "number", isOptional: true },
        { name: "objectName", type: "string", isOptional: true },
        { name: "objectKey", type: "string", isOptional: true },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),
    tableSchema({
      name: TableName.WorkType,
      columns: [
        { name: "workTypeId", type: "number", isIndexed: true },
        { name: "workTypeName", type: "string" },
        { name: "workTypeDescription", type: "string", isOptional: true },
        { name: "sortOrder", type: "number", isOptional: true },

        { name: "createdBy", type: "string", isOptional: true },
        { name: "createdOffset", type: "number", isOptional: true },

        { name: "updatedBy", type: "string", isOptional: true },
        { name: "updatedOffset", type: "number", isOptional: true },
        { name: "deletedAt", type: "number", isOptional: true },
        { name: "deletedBy", type: "string", isOptional: true },
        { name: "deletedOffset", type: "number", isOptional: true },
        { name: "icon", type: "string", isOptional: true },
        { name: "locationRequired", type: "boolean", isOptional: true },
        { name: "locationDisabled", type: "boolean", isOptional: true },
        { name: "locationHidden", type: "boolean", isOptional: true },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),
    // LINE STYLES
    tableSchema({
      name: TableName.LineStyle,
      columns: [
        { name: "lineStyleId", type: "number", isIndexed: true },
        { name: "lineStyleName", type: "string", isOptional: true },
        { name: "color", type: "string", isOptional: true },
        { name: "weight", type: "string", isOptional: true },
        { name: "opacity", type: "string", isOptional: true },
        { name: "lineCap", type: "string", isOptional: true },
        { name: "lineJoin", type: "string", isOptional: true },
        { name: "dashArray", type: "string", isOptional: true },
        { name: "dashOffset", type: "string", isOptional: true },
        { name: "thumbnail", type: "string", isOptional: true }, // Handle as string, might be base64 or URL
        { name: "createdBy", type: "string", isOptional: true },
        { name: "createdOffset", type: "number", isOptional: true },
        { name: "updatedBy", type: "string", isOptional: true },
        { name: "updatedOffset", type: "number", isOptional: true },
        { name: "deletedAt", type: "number", isOptional: true },
        { name: "deletedBy", type: "string", isOptional: true },
        { name: "deletedOffset", type: "number", isOptional: true },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),
    tableSchema({
      name: TableName.MapSymbol,
      columns: [
        { name: "mapSymbolId", type: "number", isIndexed: true },
        { name: "mapSymbolName", type: "string", isOptional: true },
        { name: "icon", type: "string", isOptional: true },
        { name: "shadowIcon", type: "string", isOptional: true },
        { name: "iconSize", type: "string", isOptional: true },
        { name: "shadowSize", type: "string", isOptional: true },
        { name: "iconAnchor", type: "string", isOptional: true },
        { name: "shadowAnchor", type: "string", isOptional: true },
        { name: "popupAnchor", type: "string", isOptional: true },
        { name: "createdBy", type: "string", isOptional: true },
        { name: "createdOffset", type: "number", isOptional: true },
        { name: "updatedBy", type: "string", isOptional: true },
        { name: "updatedOffset", type: "number", isOptional: true },
        { name: "deletedAt", type: "number", isOptional: true },
        { name: "deletedBy", type: "string", isOptional: true },
        { name: "deletedOffset", type: "number", isOptional: true },
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),

    tableSchema({
      name: TableName.Upload,
      columns: [
        { name: "blob_name", type: "string" },
        { name: "file_name", type: "string" },
        { name: "file_size", type: "number" },
        { name: "uploaded_blocks", type: "string" }, // JSON string
        { name: "created_at", type: "number" },
        { name: "updated_at", type: "number" },
      ],
    }),
  ],
})
