import type { IAuditLog } from "../interfaces/auditLog.interface"
import { apiService } from "./api.service"
import { BASE_API_URL } from "../constants/commonStrings.constant"

class AuditService {
  async getAuditLogs(objectName: string, objectId: number): Promise<IAuditLog[]> {
    const url = BASE_API_URL + `/auditlog`
    const response = await apiService({
      url,
      method: "GET",
      params: {
        objectName,
        objectId,
      },
      withCredentials: false,
    })
    const { data } = response

    return data
  }
}

export const auditService = new AuditService()
