import React, { useEffect, useState } from "react"
import { LinearProgress, Box } from "@mui/material"

function StorageUsageBar() {
  const [usagePercentage, setUsagePercentage] = useState(0)

  useEffect(() => {
    async function fetchStorageEstimate() {
      if ("storage" in navigator && "estimate" in navigator.storage) {
        try {
          const estimate = await navigator.storage.estimate()
          console.log("Storage estimate:", estimate)

          if (estimate.usage && estimate.quota) {
            const percentage = (estimate.usage / estimate.quota) * 100
            setUsagePercentage(percentage)
          }
        } catch (error) {
          console.error("Error fetching storage estimate:", error)
          // Handle error or set default values
        }
      } else {
        console.warn("Storage API not supported in this browser.")
        // Provide fallback behavior or notify the user
      }
    }

    fetchStorageEstimate()

    // Update periodically, e.g., every 1 minute
    const intervalId = setInterval(fetchStorageEstimate, 60000)
    return () => clearInterval(intervalId)
  }, [])

  return (
    <Box width="100%">
      <LinearProgress variant="determinate" value={usagePercentage} sx={{ height: 2 }} color="error" />
    </Box>
  )
}

export default StorageUsageBar
