import React, { type FC, useEffect, useState, useContext, useCallback } from "react"
import type { FormDataObject, FormDataTypeProps, IFormAttachment } from "../../../interfaces"
// import type { Theme } from "@mui/material"
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  createTheme,
  Dialog,
  IconButton,
  Slide,
  Stack,
  ThemeProvider,
  Typography,
  // useMediaQuery,
  useTheme,
} from "@mui/material"
import { Close } from "@mui/icons-material"
import type { TransitionProps } from "@mui/material/transitions"
import { hexToRGB, toBase64 } from "../utilities"

import Lightbox from "yet-another-react-lightbox"
import "yet-another-react-lightbox/styles.css"
import "yet-another-react-lightbox/plugins/thumbnails.css"
import "yet-another-react-lightbox/plugins/captions.css"
import { Zoom, Thumbnails, Captions } from "yet-another-react-lightbox/plugins"
import { HiCamera, HiOutlineTrash, HiPhoto, HiTrash } from "react-icons/hi2"
import { ImCompass } from "react-icons/im"
import { v4 } from "uuid"
import { useAttachments } from "../../../hooks/data/useAttachments"
import type Attachment from "../../../model/Attachment"
import SettingsContext from "../../../contexts/settings.context"
import { UPDATE_SETTINGS } from "../../../contexts/reducers/settings.reducer"
import "react-html5-camera-photo/build/css/index.css"
import WebcamCapture from "../../Molecules/WebcamCapture/WebcamCapture.component"
import { featureService } from "../../../services"
import ConfirmationButton from "../../Molecules/ConfirmationButton/ConfirmationButton.component"
import ImageWithFallback from "../../Atoms/ImageWithFallback/ImageWithFallback.component"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ImageUploaderInput: FC<FormDataObject & FormDataTypeProps> = ({
  onDataChange,
  onFileUpload,
  onFileDelete,
  onAttachmentDelete,
  options,
  targetName,
  targetRefId,
  ...props
}: FormDataTypeProps) => {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const [openLightBox, setOpenLightBox] = React.useState(false)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [position, setPosition] = useState({ latitude: 0, longitude: 0 })
  const [orientation, setOrientation] = useState(0)
  const path = `${targetName}s/${targetRefId}/${props.id}`
  const { $screenshots, addAttachment, deleteAttachment, error } = useAttachments(path)
  const { settingsState, updateSettings } = useContext(SettingsContext)
  const [allImages, setImages] = useState<IFormAttachment[]>([])
  // const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"))
  //Watch the lat/lon and orientation in real time

  useEffect(() => {
    // Watch position
    const watchId = navigator.geolocation.watchPosition(
      (pos) => {
        setPosition({
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
        })
      },
      (err) => console.error(err),
      { enableHighAccuracy: true, maximumAge: 0 },
    )

    window.addEventListener("deviceorientation", handleOrientation)
    window.addEventListener("deviceorientationabsolute", handleOrientation)

    // Cleanup
    return () => {
      navigator.geolocation.clearWatch(watchId)
      window.removeEventListener("deviceorientation", handleOrientation)
      window.removeEventListener("deviceorientationabsolute", handleOrientation)
    }
  }, [])

  const handleOnFeatureAttachmentClick = useCallback(async (targetRefId: number | undefined, fieldId: string) => {
    try {
      if (!targetRefId) return
      const attachments = await featureService.getFeatureAttachmentsByField(targetRefId?.toString() ?? "", fieldId)
      if (attachments && attachments.length > 0) {
        setImages(attachments)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    if (props.id) {
      handleOnFeatureAttachmentClick(targetRefId, props.id)
    }
  }, [targetRefId, props.id])

  // console.log(path, $attachments, props)
  useEffect(() => {
    setOpen(settingsState.cameraOpen ?? false)
  }, [settingsState.cameraOpen])

  // Listen for orientation changes
  const handleOrientation = useCallback((event: any) => {
    const { alpha, beta, gamma, webkitCompassHeading } = event // alpha represents compass direction
    // console.log(event)
    if (webkitCompassHeading) {
      setOrientation(webkitCompassHeading)
    } else {
      let compass = -(alpha + (beta * gamma) / 90)
      compass -= Math.floor(compass / 360) * 360
      setOrientation(compass)
    }
  }, [])

  // @ts-ignore
  const handleClickOpen = useCallback(() => {
    if (
      typeof DeviceOrientationEvent !== "undefined" &&
      // @ts-ignore
      typeof DeviceOrientationEvent.requestPermission === "function"
    ) {
      // @ts-ignore
      DeviceOrientationEvent.requestPermission()
        // @ts-ignore
        .then((permissionState) => {
          if (permissionState === "granted") {
            window.addEventListener("deviceorientation", handleOrientation)
          } else {
            console.error("Device orientation permission not granted.")
            // Handle the lack of permission, e.g., inform the user
          }
        })
        .catch(console.error)
    }

    setOpen(true)
  }, [])

  const handleClose = () => {
    updateSettings(UPDATE_SETTINGS, {
      ...settingsState,
      cameraOpen: false,
    })
    setOpen(false)
  }

  const handleOnImageClick = (index: number) => {
    setActiveIndex(index)
    setOpenLightBox(true)
  }

  const handleOnDelete = async (image: Attachment) => {
    setIsDeleting(true)
    deleteAttachment(image.id)
    // remove the image from the allImages array
    const index = allImages.findIndex((img) => img.id === image.id)
    if (index > -1) {
      allImages.splice(index, 1)
      setImages([...allImages])
    }
    if (!error) setIsDeleting(false)
  }

  const handleOnUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    if (event.target.files && event.target.files.length > 0) {
      const promises = []
      const newAttachments: Attachment[] = []

      for (const imageFile of event.target.files) {
        const uid = v4()
        const base64Image = await toBase64(imageFile)
        const attachment = {
          data: base64Image as string,
          name: `image_${props.id}_${uid}`,
          type: "library",
          fieldId: props.id,
          fileName: imageFile.name,
          path,
        } as Attachment
        console.log(attachment)
        promises.push(addAttachment(attachment))
        newAttachments.push(attachment)
      }

      await Promise.all(promises)
      setImages((prev) => [...prev, ...newAttachments])
      setLoading(false)
    }
  }

  const handleOnScreenshot = async (base64Image: string) => {
    const uid = v4()
    const fileName = `image ${allImages ? allImages.length + 1 : 1}`
    const attachment = {
      data: base64Image as string,
      name: `image_${props.id}_${uid}`,
      type: "camera",
      fieldId: props.id,
      latitude: position.latitude,
      longitude: position.longitude,
      orientation,
      fileName,
      path,
    } as Attachment
    await addAttachment(attachment)
    setImages((prev) => [...prev, attachment])
  }

  const renderImagePreview = () => (
    <Box left={16} bottom={16} position={"fixed"}>
      <Badge badgeContent={$screenshots ? $screenshots.length : null} color="secondary" max={999}>
        <Box
          width={100}
          height={100}
          overflow={"hidden"}
          borderRadius={4}
          border={`solid 2px ${theme.palette.secondary.main}`}>
          {$screenshots && $screenshots.length > 0 && $screenshots[$screenshots.length - 1].data && (
            <img
              width={"100%"}
              height={"100%"}
              alt="test"
              style={{ objectFit: "cover" }}
              src={$screenshots[$screenshots.length - 1].data ?? ""}
            />
          )}
        </Box>
      </Badge>
    </Box>
  )

  // Dynamic style for rotating the compass icon
  const compassStyle = {
    transform: `rotate(${-(orientation + 45)}deg)`,
    transition: "transform 0.5s linear", // Smooth transition for rotation
  }

  // const videoConstraints: MediaTrackConstraints = useMemo(() => {
  //   return {
  //     facingMode,
  //     width,
  //     height,
  //     zoom: scale / 100,
  //   }
  // }, [scale, settingsState.cameraOpen, facingMode])

  const renderDialog = () => (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      sx={{ bgcolor: theme.palette.grey[900] }}
      TransitionComponent={Transition}>
      <Stack
        height={"100%"}
        position={"relative"}
        justifyContent={"center"}
        alignItems={"center"}
        bgcolor={theme.palette.grey[900]}>
        <WebcamCapture
          onTakePhoto={async (dataUri) => {
            await handleOnScreenshot(dataUri)
          }}
        />

        <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
          <Box
            bgcolor={hexToRGB(theme.palette.grey[900], 0.5)}
            alignItems={"center"}
            sx={{ backdropFilter: "blur(5px)" }}
            width={"100%"}
            justifyContent={"space-between"}
            py={1}
            px={2}
            display={"flex"}
            flexDirection={"row"}
            position={"fixed"}
            top={0}
            left={0}>
            <Box flexDirection={"row"} alignItems={"center"} display={"flex"}>
              <Box style={compassStyle} sx={{ color: "white" }}>
                <ImCompass fontSize="24" />
              </Box>
              <Typography variant="body1" color={"white"} ml={2}>
                {orientation ? orientation.toFixed(1) : "N/A"}°
              </Typography>
            </Box>
            <Typography variant="body1" color={"white"}>
              {position.longitude.toFixed(4)}, {position.latitude.toFixed(4)}
            </Typography>
            <ThemeProvider theme={createTheme({ palette: { mode: "dark" } })}>
              <IconButton onClick={handleClose} aria-label="close" id="cancel">
                <Close fontSize={"small"} />
              </IconButton>
            </ThemeProvider>
          </Box>
        </Box>

        {$screenshots && $screenshots.length > 0 && renderImagePreview()}
      </Stack>
    </Dialog>
  )

  const renderImage = (image: Attachment, index: number) => {
    let src = ""
    let fallbackSrc = ""
    if (image.thumbnailUrl !== undefined && image.thumbnailUrl !== "") {
      src = image.thumbnailUrl
      fallbackSrc = image.url
    } else if (image.url && image.url !== "") {
      src = image.url
    } else if (image.data && image.data !== "") {
      src = image.data
    }

    if (src === "" && image.attachmentId && image.path) {
      const featureId = image.path.split("/")[1]
      src = featureService.getFeatureAttachmentURL(featureId, image.attachmentId ?? 0)
      // src = `https://placehold.co/150x150?text=${image.name}`
    } else if (image.data && image.data !== "") {
      src = image.data
    }

    return (
      <Box position={"relative"} key={index}>
        <Box
          bgcolor={theme.palette.background.paper}
          my={1}
          width={150}
          height={150}
          overflow={"hidden"}
          border={`solid 2px ${theme.palette.divider}`}
          borderRadius={3}
          position={"relative"}
          component={"div"}
          style={{ cursor: "pointer" }}
          onClick={() => handleOnImageClick(index)}>
          <ImageWithFallback
            width={"100%"}
            height={"100%"}
            src={src}
            fallbackSrc={fallbackSrc}
            alt="test"
            style={{ objectFit: "cover" }}
          />
        </Box>

        <ConfirmationButton
          title="Are you sure?"
          message="This will delete the image from this feature form."
          disabled={props.readOnly}
          size="small"
          color="error"
          disableElevation
          onConfirm={async () => handleOnDelete(image)}
          onCancel={() => {}}
          sx={{
            position: "absolute",
            top: -4,
            right: -6,
            bgcolor: theme.palette.error.main,
            "&:hover": {
              bgcolor: theme.palette.error.dark,
            },
          }}
          stylecolor="#f00"
          iconButton={true}>
          {isDeleting ? <CircularProgress size={20} /> : <HiOutlineTrash color="white" />}
        </ConfirmationButton>
        {/* <IconButton
          disabled={props.readOnly}
          onClick={async () => handleOnDelete(image)}
          size="small"
          sx={{
            position: "absolute",
            top: -4,
            right: -6,
            bgcolor: theme.palette.error.main,
            "&:hover": {
              bgcolor: theme.palette.error.dark,
            },
          }}>
          {isDeleting ? <CircularProgress size={20} /> : <HiTrash color="white" />}
        </IconButton> */}
      </Box>
    )
  }

  const renderLightBox = () => {
    // const allImages = [...($attachments ?? []), ...($screenshots ?? [])]
    return (
      <Lightbox
        carousel={{ finite: true }}
        index={activeIndex}
        open={openLightBox}
        plugins={[Zoom, Thumbnails, Captions]}
        close={() => setOpenLightBox(false)}
        slides={
          allImages &&
          allImages.map((image) => {
            let src = image.url ? image.url : image.data ? image.data : ""

            if (src === "" && image.attachmentId !== undefined && image.path) {
              const featureId = image.path.split("/")[1]
              src = featureService.getFeatureAttachmentURL(featureId, image.attachmentId ?? 0)
              // src = `https://placehold.co/150x150?text=${image.name}`
            }

            return {
              src,
              description: (
                <>
                  <Box
                    bgcolor={hexToRGB(theme.palette.grey[900], 0.5)}
                    alignItems={"center"}
                    width={"100%"}
                    justifyContent={"space-between"}
                    py={1}
                    px={2}
                    display={"flex"}
                    flexDirection={"row"}
                    position={"fixed"}
                    top={0}
                    left={0}>
                    <Box flexDirection={"row"} display={"flex"}>
                      {image.orientation && (
                        <Box
                          style={{
                            transform: `rotate(${-(image.orientation + 45)}deg)`,
                            transition: "transform 0.5s linear", // Smooth transition for rotation
                          }}
                          sx={{ color: "white" }}>
                          <ImCompass fontSize="24" />
                        </Box>
                      )}
                      <Typography variant="body1" color={"white"} ml={2}>
                        {image.orientation ? image.orientation.toFixed(1) : "N/A"}°
                      </Typography>
                    </Box>
                    <Typography variant="body1" color={"white"}>
                      {image.longitude ? image.longitude.toFixed(4) + ", " : ""}{" "}
                      {image.latitude ? image.latitude.toFixed(4) : ""}
                    </Typography>
                  </Box>
                </>
              ),
            }
          })
        }
      />
    )
  }

  return (
    <Box borderRadius={2} border={`solid 1px ${theme.palette.divider}`} bgcolor={theme.palette.background.paper} p={2}>
      <Stack>
        <Typography color={"text.secondary"} marginBottom={1}>
          {props.label} {props.required && "(Required)"}
        </Typography>
        <Stack flexWrap={"wrap"} direction={"row"} alignItems={"center"} gap={1}>
          {/* {$attachments &&
            $attachments.length > 0 &&
            $attachments.map((image, index) => {
              return renderImage(image, index)
            })}
          {$screenshots &&
            $screenshots.length > 0 &&
            $screenshots.map((image, index) => {
              return renderImage(image, index)
            })} */}
          {allImages &&
            allImages.length > 0 &&
            allImages.map((image, index) => {
              return renderImage(image as Attachment, index)
            })}

          {/* {!isDesktop && ( */}
          <Box height={150} width={150}>
            <Button
              variant="outlined"
              disabled={props.readOnly}
              sx={{ height: "100%", borderRadius: 3 }}
              fullWidth
              onClick={handleClickOpen}>
              <Stack flexDirection={"column"} alignItems={"center"}>
                <HiCamera size={24} />
                <Typography fontSize={14} textTransform={"capitalize"}>
                  Camera
                </Typography>
              </Stack>
            </Button>
          </Box>
          {/* )} */}

          <Box height={150} width={150} position={"relative"}>
            <Button
              variant="outlined"
              disabled={props.readOnly || loading}
              style={{ cursor: "pointer" }}
              sx={{ height: "100%", borderRadius: 3 }}
              fullWidth>
              <input
                type="file"
                multiple
                style={{
                  cursor: "pointer",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  opacity: 0,
                }}
                name="myImage"
                accept="image/*"
                onChange={handleOnUpload}
              />
              <Stack flexDirection={"column"} alignItems={"center"}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <HiPhoto size={24} />
                    <Typography fontSize={14} textTransform={"capitalize"}>
                      Photo Library
                    </Typography>
                  </>
                )}
              </Stack>
            </Button>
          </Box>
        </Stack>
      </Stack>
      {renderDialog()}
      {renderLightBox()}
    </Box>
  )
}

export default ImageUploaderInput
