import { useState, useCallback, useRef, useContext, useEffect } from "react"
import { useDatabase } from "@nozbe/watermelondb/hooks"
import { Q } from "@nozbe/watermelondb"
import type Attachment from "../../model/Attachment"
import type Upload from "../../model/Upload"
import { TableName } from "../../model/schema"
import useObservable from "../useObservable"
import type { BlockBlobClient } from "@azure/storage-blob"
import { BlobServiceClient } from "@azure/storage-blob"
import { storageService } from "../../services/storage.service"
import SettingsContext from "../../contexts/settings.context"
import { deleteFileData, getFileData, saveFileData } from "../../utils/fileCache.util"
import { updateWatermelonDBObject } from "../../utils/data.util"
import { resizeImage } from "../../utils/image.util"

function dataURLtoBlob(dataurl: string) {
  const arr = dataurl.split(",")
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

// Function to extract expiry date from SAS token
function getSasTokenExpiry(sasToken: string) {
  if (sasToken.startsWith("?")) {
    sasToken = sasToken.substring(1)
  }

  const params = new URLSearchParams(sasToken)
  const se = params.get("se")

  if (se) {
    const decodedSe = decodeURIComponent(se)
    const expiryDate = new Date(decodedSe)
    return expiryDate
  }

  return null
}

export function useAttachments(path: string) {
  const database = useDatabase()
  const [error, setError] = useState<any | undefined>()
  const blobServiceClient = useRef<BlobServiceClient | null>(null)
  const { settingsState, setSasToken } = useContext(SettingsContext)
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  const initializeBlobServiceClient = useCallback(async () => {
    if (!blobServiceClient.current) {
      let sasToken = settingsState.sasToken
      let sasTokenExpiry = settingsState.sasTokenExpiry ? new Date(settingsState.sasTokenExpiry) : new Date()

      if (!sasToken || !sasTokenExpiry || new Date() >= sasTokenExpiry) {
        try {
          sasToken = await storageService.getAzureSAS("", true)
          console.log("Retrieved SAS token:", sasToken)
          sasTokenExpiry = getSasTokenExpiry(sasToken) || new Date()
          setSasToken(sasToken, sasTokenExpiry) // Update SAS token in settings
        } catch (err) {
          console.warn("Failed to retrieve SAS token. Device might be offline.", err)
          return // Exit if we can't get a SAS token
        }
      }

      const client = new BlobServiceClient(
        `https://${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net?${sasToken}`,
      )
      blobServiceClient.current = client
    }
  }, [settingsState.sasToken, setSasToken])

  const attachmentsQuery = database.collections
    .get<Attachment>(TableName.Attachment)
    .query(Q.where("path", path), Q.where("type", "library"))

  const screenshotsQuery = database.collections
    .get<Attachment>(TableName.Attachment)
    .query(Q.where("path", path), Q.where("type", "camera"))

  const $attachments = useObservable<Attachment[]>(attachmentsQuery.observe(), [], [database, path])
  const $screenshots = useObservable<Attachment[]>(screenshotsQuery.observe(), [], [database, path])

  async function uploadFileInBlocks(
    blockBlobClient: BlockBlobClient,
    fileBlob: Blob,
    blobName: string,
    attachmentData: Attachment,
  ) {
    const blockSize = 4 * 1024 * 1024 // 4MB
    const totalSize = fileBlob.size
    const totalBlocks = Math.ceil(totalSize / blockSize)

    // Retrieve existing upload state or initialize a new one
    const upload = await database.collections
      .get<Upload>(TableName.Upload)
      .query(Q.where("blob_name", blobName))
      .fetch()

    let uploadRecord: Upload
    if (upload.length > 0) {
      uploadRecord = upload[0]
    } else {
      // Create a new upload record
      await database.write(async () => {
        uploadRecord = await database.collections.get<Upload>(TableName.Upload).create((newUpload) => {
          newUpload.blobName = blobName
          newUpload.fileName = attachmentData.name
          newUpload.fileSize = totalSize
          newUpload.uploadedBlocks = []
          newUpload.createdAt = Date.now()
          newUpload.updatedAt = Date.now()
        })
      })
    }

    const uploadedBlocks = uploadRecord.uploadedBlocks

    // Save the file data to IndexedDB if not already saved
    const cachedFileBlob = await getFileData(blobName)
    if (!cachedFileBlob) {
      try {
        await saveFileData(blobName, fileBlob)
      } catch (error) {
        console.error(`Failed to save file data for ${blobName}:`, error)
        throw error
      }
    } else {
      fileBlob = cachedFileBlob
    }

    const blockIds = []

    for (let i = 0; i < totalBlocks; i++) {
      const blockId = btoa(`block-${i.toString().padStart(6, "0")}`)
      blockIds.push(blockId)

      // Skip already uploaded blocks
      if (uploadedBlocks.includes(blockId)) {
        continue
      }

      const start = i * blockSize
      const end = Math.min(start + blockSize, totalSize)
      const blockData = fileBlob.slice(start, end)

      try {
        // Upload the block
        await blockBlobClient.stageBlock(blockId, blockData, end - start)

        // Update the upload state
        uploadedBlocks.push(blockId)

        // Persist the updated state in WatermelonDB
        await database.write(async () => {
          await uploadRecord.update((u) => {
            u.uploadedBlocks = uploadedBlocks
            u.updatedAt = Date.now()
          })
        })

        console.log(`Uploaded block ${i + 1}/${totalBlocks}`)
      } catch (error) {
        console.error(`Error uploading block ${i + 1}:`, error)
        throw error
      }
    }

    // Commit the blocks
    await blockBlobClient.commitBlockList(blockIds)

    // Set metadata
    const metadata = {
      FileName: attachmentData.name ?? "Uploaded Image",
      ContentType: fileBlob.type,
      // Add any other metadata you want to store
    }
    await blockBlobClient.setMetadata(metadata)

    // Set HTTP headers
    const blobHttpHeaders = {
      blobContentType: fileBlob.type,
      blobContentDisposition: `attachment; filename="${attachmentData.name ?? "Uploaded Image"}"`,
    }
    await blockBlobClient.setHTTPHeaders(blobHttpHeaders)

    // Remove the upload record from WatermelonDB
    await database.write(async () => {
      await uploadRecord.markAsDeleted()
    })

    // Remove the file data from IndexedDB
    try {
      await deleteFileData(blobName)
    } catch (error) {
      console.error(`Failed to delete file data for ${blobName}:`, error)
    }

    console.log("Upload complete")
  }

  const resumeIncompleteUploads = useCallback(async () => {
    if (!isOnline) {
      console.log("Device is offline. Cannot resume uploads.")
      return
    }

    try {
      await initializeBlobServiceClient()
    } catch (err) {
      console.warn("Device is offline or failed to initialize blobServiceClient:", err)
    }

    const incompleteUploads = await database.collections.get<Upload>(TableName.Upload).query().fetch()

    for (const uploadRecord of incompleteUploads) {
      try {
        const blobName = uploadRecord.blobName
        const containerClient = blobServiceClient.current?.getContainerClient(
          process.env.REACT_APP_AZURE_STORAGE_CONTAINER ?? "",
        )
        const blockBlobClient = containerClient?.getBlockBlobClient(blobName)

        // Retrieve the file data from IndexedDB
        const fileBlob = await getFileData(blobName)

        if (!fileBlob) {
          console.error(`File data not found for ${blobName}`)
          // Cannot resume upload without file data; remove upload record
          await database.write(async () => {
            await uploadRecord.markAsDeleted()
          })
          continue
        }

        // Resume uploading the remaining blocks
        if (!blockBlobClient) {
          throw new Error("Block blob client not found")
        }

        await uploadFileInBlocks(blockBlobClient, fileBlob, blobName, { name: uploadRecord.fileName } as Attachment)
        console.log(`Successfully resumed and completed upload for ${blobName}`)
      } catch (error) {
        console.error(`Error resuming upload for ${uploadRecord.blobName}:`, error)
      }
    }
  }, [database, blobServiceClient, initializeBlobServiceClient, isOnline])

  // Effect to handle online/offline status
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true)
      resumeIncompleteUploads()
    }

    const handleOffline = () => {
      setIsOnline(false)
    }

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [resumeIncompleteUploads])

  // Effect to resume incomplete uploads on app start
  useEffect(() => {
    if (isOnline) {
      resumeIncompleteUploads()
    }
  }, [resumeIncompleteUploads, isOnline])

  const addAttachment = useCallback(
    async (newAttachmentData: Attachment) => {
      try {
        await initializeBlobServiceClient()
      } catch (err) {
        console.warn("Device is offline or failed to initialize blobServiceClient:", err)
      }

      try {
        // Always store the attachment metadata in WatermelonDB
        const { data, ...attachmentDataWithoutData } = newAttachmentData

        if (!data) {
          throw new Error("No data found in the attachment")
        }

        // Generate thumbnail
        const thumbnailDataUrl = await resizeImage(data, 300, 300)

        const fileBlob = dataURLtoBlob(data)
        const thumbnailBlob = dataURLtoBlob(thumbnailDataUrl)
        const totalSize = fileBlob.size

        const blobName = `Documents/${newAttachmentData.path}/${newAttachmentData.name}`
        const thumbnailBlobName = `Documents/${newAttachmentData.path}/${newAttachmentData.name}_thumbnail.jpg`

        await database.write(async () => {
          await database.collections.get<Attachment>(TableName.Attachment).create((attachment) => {
            updateWatermelonDBObject(attachment, attachmentDataWithoutData)
          })

          await database.collections.get<Upload>(TableName.Upload).create((newUpload) => {
            newUpload.blobName = blobName
            newUpload.fileName = attachmentDataWithoutData.name
            newUpload.fileSize = totalSize
            newUpload.uploadedBlocks = []
            newUpload.createdAt = Date.now()
            newUpload.updatedAt = Date.now()
          })

          await database.collections.get<Upload>(TableName.Upload).create((newUpload) => {
            newUpload.blobName = thumbnailBlobName
            newUpload.fileName = `${attachmentDataWithoutData.name}_thumbnail.jpg`
            newUpload.fileSize = thumbnailBlob.size
            newUpload.uploadedBlocks = []
            newUpload.createdAt = Date.now()
            newUpload.updatedAt = Date.now()
          })
        })

        // Save file data to IndexedDB using the attachment ID
        await saveFileData(blobName, fileBlob)
        await saveFileData(thumbnailBlobName, thumbnailBlob)

        // If online and blobServiceClient is initialized, attempt to upload immediately
        if (isOnline && blobServiceClient.current) {
          await resumeIncompleteUploads()
        } else {
          console.warn("Attachment saved for later upload when online.")
        }

        return newAttachmentData
      } catch (err) {
        setError(err)
        console.error("Error saving attachment:", err)
      }
    },
    [database, initializeBlobServiceClient, isOnline],
  )

  const addAttachment_OLD = useCallback(
    async (newAttachmentData: Attachment) => {
      try {
        await initializeBlobServiceClient()
        // Construct the blob name to match the server's expected path
        const blobName = `Documents/${newAttachmentData.path}/${newAttachmentData.name}`

        // Get a container client
        const containerClient = blobServiceClient.current?.getContainerClient(
          process.env.REACT_APP_AZURE_STORAGE_CONTAINER ?? "",
        )

        // Get a block blob client
        const blockBlobClient = containerClient?.getBlockBlobClient(blobName)

        // Convert base64 data URL to Blob
        if (!newAttachmentData.data) {
          throw new Error("No data found in the attachment")
        }
        const fileBlob = dataURLtoBlob(newAttachmentData.data)

        // Start the block upload process
        if (!blockBlobClient) {
          throw new Error("Block blob client not found")
        }
        await uploadFileInBlocks(blockBlobClient, fileBlob, blobName, newAttachmentData)

        // Set the URL in the attachment data to match the server's expected format
        newAttachmentData.url = blobName

        // Remove the data field before saving to the database
        const { data, ...attachmentDataWithoutData } = newAttachmentData

        // Save the attachment metadata to WatermelonDB
        await database.write(async () => {
          await database.collections.get<Attachment>(TableName.Attachment).create((attachment) => {
            Object.assign(attachment, attachmentDataWithoutData)
          })
        })

        return newAttachmentData
      } catch (err) {
        setError(err)
        console.error("Error uploading attachment:", err)
      }
    },
    [database, initializeBlobServiceClient, blobServiceClient],
  )

  // Function to update an existing attachment
  const updateAttachment = useCallback(
    async (updatedAttachmentData: Attachment) => {
      try {
        await database.write(async () => {
          const attachment = await database.collections.get(TableName.Attachment).find(updatedAttachmentData.id)
          await attachment.update((attachment) => {
            Object.assign(attachment, updatedAttachmentData)
          })
        })
      } catch (err) {
        setError(err)
        console.error("Error updating attachment:", err)
      }
    },
    [database],
  )

  // Function to delete an attachment
  const deleteAttachment = useCallback(
    async (id: string): Promise<string | undefined> => {
      try {
        await database.write(async () => {
          const attachment = await database.collections.get(TableName.Attachment).find(id)
          await attachment.markAsDeleted()
        })
        return "Deleted Successfully"
      } catch (err) {
        setError(err)
        console.error("Error deleting attachment:", err)
      }
    },
    [database],
  )

  return {
    $attachments,
    $screenshots,
    addAttachment,
    updateAttachment,
    deleteAttachment,
    resumeIncompleteUploads,
    error,
    isOnline,
  }
}
