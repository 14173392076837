import { Button, DialogActions, useTheme } from "@mui/material"
import React from "react"
import type { FormDataTypeProps } from "../../../interfaces/formBuilder.interface"
import type { PickersActionBarProps } from "@mui/x-date-pickers"
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"

const TimeFieldInput: React.FC<FormDataTypeProps> = ({ onDataChange, ...props }: FormDataTypeProps) => {

  const [value, setValue] = React.useState(props.value ?? null)
  // Time picker inheriting from PickersActionBarProps
  const customActionBar = ({
    onAccept,
    onSetToday,
    // other hooks in-case we implement them later
    // onCancel,
    // onClear,
  }: PickersActionBarProps) => {
    return (
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button sx={{ color: "orange" }} onClick={onSetToday}>
          NOW
        </Button>
        <Button onClick={onAccept}>OK</Button>
      </DialogActions>
    )
  }

  const theme = useTheme()

  const onChange = (e: dayjs.Dayjs | null) => {
    setValue(e?.toDate())
    onDataChange(props.id, e?.format())
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label={props.required ? `${props.label} (Required)` : props.label}
        readOnly={props.readOnly}
        disabled={props.disabled}
        value={value ? dayjs.utc(value) : null}
        onChange={onChange}
        slots={{
          actionBar: customActionBar,
        }}
        slotProps={{
          textField: {
            size: "small",
            autoComplete: "off",
            name: props.name,
            fullWidth: true,
            helperText: props.hintText,
            sx: {
              bgcolor: theme.palette.background.paper,
            },
          },
          layout: {
            sx: {
              display: "block",
            },
          },
        }}
      />
    </LocalizationProvider>
  )
}

export default TimeFieldInput
