import { Box, createTheme, IconButton, Stack, ThemeProvider } from "@mui/material"

import { useState, useRef, useEffect, useCallback } from "react"
import { FaCameraRotate } from "react-icons/fa6"
import { HiZoomIn, HiZoomOut } from "react-icons/hi"
import { HiCamera } from "react-icons/hi2"
import { useLongPress } from "@uidotdev/usehooks"

const WebcamCapture = ({ onTakePhoto }: { onTakePhoto: (data: string) => void }) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const streamRef = useRef<MediaStream | null>(null)

  const [scale, setScale] = useState<number>(1)
  const [facingMode, setFacingMode] = useState<"user" | { exact: "environment" }>({ exact: "environment" })
  const [hasMultipleCameras, setHasMultipleCameras] = useState<boolean>(false)
  const [isStreamActive, setIsStreamActive] = useState<boolean>(false)

  const zoomOutAttrs = useLongPress(() => setScale((prevScale) => (prevScale > 0.1 ? prevScale - 0.1 : 0.1)))

  const zoomInAttrs = useLongPress(() => setScale((prevScale) => prevScale + 0.1))

  useEffect(() => {
    ;(async () => startWebcam())()

    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop())
      }
    }
  }, [])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && !isStreamActive) {
        startWebcam()
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange)

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [isStreamActive])

  const keepAwake = async () => {
    try {
      await navigator.wakeLock.request("screen")
    } catch (err) {
      console.error("Wake Lock error:", err)
    }
  }

  useEffect(() => {
    if (isStreamActive) {
      keepAwake()
    }
  }, [isStreamActive])

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const videoDevices = devices.filter((device) => device.kind === "videoinput")
      setHasMultipleCameras(videoDevices.length > 1)
    })
  }, [navigator])

  const startWebcam = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode,
          width: { ideal: 9999, max: 9999 },
          height: { ideal: 9999, max: 9999 },
        },
      })
      if (videoRef.current) {
        videoRef.current.srcObject = stream
        await videoRef.current.play()
        streamRef.current = stream
        setIsStreamActive(true)
      }
    } catch (error) {
      console.error("Error accessing webcam", error)
      setIsStreamActive(false)
    }
  }, [facingMode])

  const stopWebcam = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop())
    }
  }

  const handleStreamError = () => {
    console.error("Stream error occurred")
    setIsStreamActive(false)
    setTimeout(startWebcam, 1000)
  }

  const handleCameraRotate = async () => {
    stopWebcam()
    if (facingMode === "user") {
      setFacingMode({ exact: "environment" })
    } else {
      setFacingMode("user")
    }
    await startWebcam()
  }

  const captureImage = () => {
    if (videoRef.current && streamRef.current && canvasRef.current) {
      const video = videoRef.current
      const canvas = canvasRef.current
      const context = canvas.getContext("2d")

      // const track = streamRef.current.getVideoTracks()[0]
      // const capabilities = track.getCapabilities()
      // const settings = track.getSettings()

      // let captureWidth = settings.width || captureResolution.width
      // let captureHeight = settings.height || captureResolution.height

      // // Check if we can increase resolution for capture
      // if (capabilities.width && capabilities.width.max && capabilities.width.max > captureWidth) {
      //   captureWidth = capabilities.width.max
      // }
      // if (capabilities.height && capabilities.height.max && capabilities.height.max > captureHeight) {
      //   captureHeight = capabilities.height.max
      // }

      if (context && video.videoWidth && video.videoHeight) {
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight

        context.drawImage(video, 0, 0, canvas.width, canvas.height)

        const imageDataUrl = canvas.toDataURL("image/jpeg", 0.95)
        onTakePhoto(imageDataUrl)
      }
    }
  }

  const theme = createTheme({ palette: { mode: "dark" } })

  return (
    <ThemeProvider theme={theme}>
      <Stack
        justifyContent={"start"}
        alignItems={"center"}
        sx={{
          position: "relative",
          height: "100vh",
          width: "100%",
          marginY: 0,
          marginX: "auto",
          overflow: "hidden",
        }}>
        <Box width={"100%"} sx={{ position: "relative", width: "100%", height: "100%" }}>
          <video
            style={{
              display: isStreamActive ? "block" : "none",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            playsInline
            onEnded={handleStreamError}
            onError={handleStreamError}
            ref={videoRef}
            autoPlay
            muted
          />
          <canvas style={{ display: "none" }} ref={canvasRef} />
        </Box>
        <Stack justifyContent={"center"} direction={"row"} sx={{ position: "absolute", bottom: 12, width: "100%" }}>
          <IconButton
            size="large"
            onClick={captureImage}
            sx={{ width: 50, height: 50, mb: 2, bgcolor: "white", "&:hover": { bgcolor: "white" } }}>
            <HiCamera fontSize="24" color="black" />
          </IconButton>

          <Stack
            sx={{
              position: "absolute",
              right: 24,
              bottom: 0,
            }}>
            <IconButton
              size="large"
              {...zoomInAttrs}
              onClick={() => setScale((prevScale) => prevScale + 0.1)}
              sx={{
                width: 50,
                height: 50,
                mb: 2,
                bgcolor: "white",
                "&:hover": { bgcolor: "white" },
              }}>
              <HiZoomIn fontSize="24" color="black" />
            </IconButton>
            <IconButton
              size="large"
              {...zoomOutAttrs}
              disabled={scale === 1}
              onClick={() => setScale((prevScale) => (prevScale > 1 ? prevScale - 0.1 : 1))}
              sx={{
                width: 50,
                height: 50,
                mb: 2,
                bgcolor: "white",
                "&:disabled": { opacity: 0.5, bgcolor: "white" },
                "&:hover": { bgcolor: "white" },
              }}>
              <HiZoomOut fontSize="24" color="black" />
            </IconButton>
            {!hasMultipleCameras && (
              <IconButton
                size="large"
                onClick={async () => handleCameraRotate()}
                sx={{
                  width: 50,
                  height: 50,
                  mb: 2,
                  bgcolor: "white",
                  "&:hover": { bgcolor: "white" },
                }}>
                <FaCameraRotate fontSize="24" color="black" />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Stack>
    </ThemeProvider>
  )
}

export default WebcamCapture
