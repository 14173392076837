import { useState, type FC } from "react"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { IconButton, useTheme } from "@mui/material"
import type { FormDataTypeProps } from "../../../interfaces"
import { HiCalendar } from "react-icons/hi"

const DateFieldInput: FC<FormDataTypeProps> = ({ onDataChange, ...props }: FormDataTypeProps) => {
 // const { control, setValue } = useFormContext()
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.setDefault("America/Chicago")

  const [value, setValue] = useState(props.value ?? null)

  const onChange = (e: dayjs.Dayjs | null) => {
    const date = e?.utc().format()
    setValue(date)
    onDataChange(props.id, date)
  }

  const theme = useTheme()
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        readOnly={props.readOnly}
        disabled={props.disabled}
        value={value ? dayjs.utc(value) : null}
        onChange={onChange}
        slots={{
          openPickerButton: IconButton,
          openPickerIcon: HiCalendar,
        }}
        slotProps={{
          textField: {
            size: "small",
            fullWidth: true,
            helperText: props.hintText,
            sx: {
              bgcolor: theme.palette.background.paper,
            },
          },
        }}
        label={props.label ?? "Enter Date"}
      />
    </LocalizationProvider>
  )
}

export default DateFieldInput
