import React, { useState, useEffect, useCallback, useContext } from "react"
import { Button, IconButton, type Theme, useMediaQuery } from "@mui/material"
import { auditService } from "../../../services/audit.service"
import type { IAuditLog } from "../../../interfaces/auditLog.interface"
import AuditList from "./AuditList.component"
import { VscHistory } from "react-icons/vsc"
import LayoutContext from "../../../contexts/layout.context"

interface IProps {
  objectName: string
  objectId: number
}

const AuditButton = ({ objectName, objectId }: IProps) => {
  const [changes, setChanges] = useState<IAuditLog[]>()
  const [, setIsLoading] = useState<boolean>(false)

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
  const { openSidebar } = useContext(LayoutContext)

  const fetchLogs = useCallback(async () => {
    setIsLoading(true)
    try {
      const _changes = await auditService.getAuditLogs(objectName, objectId)
      if (_changes) {
        setChanges(_changes)
        setIsLoading(false)
      }
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchLogs()
  }, [fetchLogs])

  const handleOnClick = () => openSidebar(<AuditList logs={changes} />)

  return (
    <>
      {isDesktop ? (
        <Button
          onClick={handleOnClick}
          disabled={changes && changes.length === 0}
          sx={{ textTransform: "capitalize", borderRadius: 24, px: 2 }}
          startIcon={<VscHistory />}>
          {changes && changes.length > 0 ? changes.length : "0"}
        </Button>
      ) : (
        <IconButton color="primary" onClick={handleOnClick}>
          <VscHistory />
        </IconButton>
      )}
    </>
  )
}

export default AuditButton
