import {
  Box,
  CircularProgress,
  Container,
  List,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
  ListItemButton,
  IconButton,
  Stack,
  Chip,
  Tooltip,
  Avatar,
  Icon,
} from "@mui/material"
import React, { useState, useCallback, useEffect, useContext } from "react"

import SettingsContext from "../../contexts/settings.context"
import { VscClose } from "react-icons/vsc"
import { UPDATE_SETTINGS } from "../../contexts/reducers/settings.reducer"
import { hexToRGB } from "../../components/FormBuilder/utilities"
import type { ISurvey } from "../../interfaces"
import { surveyService } from "../../services/survey.service"
import { GoChecklist } from "react-icons/go"
import { pascalCaseToUnderscore } from "../../utils/string.util"
import LayoutContext from "../../contexts/layout.context"

function SelectSurveytList() {
  const theme = useTheme()
  const [surveys, setSurveys] = useState<ISurvey[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { settingsState, updateSettings } = useContext(SettingsContext)
  const { closeSidebar } = useContext(LayoutContext)

  const fetch = useCallback(async () => {
    setIsLoading(true)
    try {
      if (settingsState.activeProject) {
        const res = await surveyService.search("all", settingsState.activeProject.projectId ?? 0, "")
        if (res) {
          setSurveys(res)
          setIsLoading(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    fetch()
  }, [])

  const handleOnSelect = (survey: ISurvey) => {
    updateSettings(UPDATE_SETTINGS, {
      ...settingsState,
      activeSurvey: {
        surveyId: survey.surveyId,
        surveyName: survey.surveyName,
        formTitle: survey.formTitle,
        workTypeName: survey.workTypeName,
        workTypeId: survey.workTypeId,
      },
      activeFeature: undefined,
      sidebar: null,
    })
  }

  const renderLoadingContainer = () => (
    <Box width={"100%"} display={"flex"} justifyContent={"center"} py={12} alignItems={"center"}>
      <CircularProgress />
    </Box>
  )
  const renderStatusColor = (survey: ISurvey) => {
    const color: any = surveyService.getStatusThemeColorAlt(survey.status)
    return color
  }

  return (
    <>
      <Stack position={"sticky"} top={0} zIndex={50}>
        <Box
          sx={{
            p: 2,
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "space-between",
            background: "linear-gradient(-90deg, #0c355f 2%, rgba(26,47,68,1) 62%);",
            backdropFilter: "blur(10px)",
          }}>
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            <GoChecklist color="white" size={36} opacity={0.7} />
            <Stack gap={0}>
              <Typography color={"white"} fontWeight={900}>
                Surveys
              </Typography>
            </Stack>
          </Stack>
          <Stack gap={1} flexDirection={"row"}>
            <IconButton onClick={() => closeSidebar()}>
              <VscClose />
            </IconButton>
          </Stack>
        </Box>
      </Stack>
      <Container maxWidth={false} sx={{ pt: 4, marginBottom: 7 }}>
        {isLoading ? (
          renderLoadingContainer()
        ) : (
          <Box
            sx={{
              background: theme.palette.background.paper,
              borderRadius: 3,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: theme.palette.grey[300],
              overflow: "hidden",
            }}>
            <List sx={{ paddingY: 0 }} dense>
              {surveys &&
                surveys.map((survey, index) => {
                  return (
                    <ListItemButton dense sx={{ cursor: "pointer" }} key={index} onClick={() => handleOnSelect(survey)}>
                      <ListItemAvatar sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                        <Tooltip title={survey.status}>
                          <Avatar
                            sx={{
                              bgcolor: surveyService.getStatusColor(
                                survey.status,
                                settingsState.activeSurvey && settingsState.activeSurvey.surveyId === survey.surveyId,
                              ),
                            }}>
                            <Icon fontSize="small">
                              {pascalCaseToUnderscore(
                                surveyService.getStatusIcon(
                                  survey.status,
                                  settingsState.activeSurvey && settingsState.activeSurvey.surveyId === survey.surveyId,
                                ),
                              )}
                            </Icon>
                          </Avatar>
                        </Tooltip>
                      </ListItemAvatar>

                      <ListItemText
                        primaryTypographyProps={{ fontWeight: "bold" }}
                        secondary={
                          survey.formTitle &&
                          survey.workTypeName && (
                            <small>
                              {survey.formTitle} | <i>{survey.workTypeName}</i>
                            </small>
                          )
                        }
                        secondaryTypographyProps={{ fontSize: 12 }}
                        primary={survey.surveyName}
                      />
                      {settingsState.activeSurvey && settingsState.activeSurvey?.surveyId === survey.surveyId && (
                        <Chip
                          size="small"
                          sx={{
                            backgroundColor: hexToRGB(theme.palette.info.main, 0.1),
                            color: theme.palette.info.main,
                            marginRight: 1,
                          }}
                          label="Active"
                        />
                      )}
                      <Chip
                        size="small"
                        sx={{
                          backgroundColor: renderStatusColor(survey).backgroundColor,
                          color: renderStatusColor(survey).color,
                          textTransform: "capitalize",
                          fontSize: 12,
                        }}
                        label={`${survey?.status}`}
                      />
                    </ListItemButton>
                  )
                })}
            </List>
          </Box>
        )}
      </Container>
    </>
  )
}

export default SelectSurveytList
